.whatsapp-container {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: #25D366; /* WhatsApp green color */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1000; /* Ensure it appears above other elements */
    overflow: hidden; /* Hide overflow for wave effect */
    animation: floating 1.5s ease-in-out infinite; /* Add floating animation */
  }
  
  .whatsapp-icon {
    width: 60%; /* Adjust width of the icon */
    height: auto; /* Maintain aspect ratio */
    z-index: 1; /* Ensure the icon is above the wave */
  }
  
  /* Wave effect styles */
  .wave {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200%;
    height: 100px; /* Height of the wave */
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translateX(-50%);
    animation: wave-animation 1.5s infinite; /* Wave animation */
    z-index: 0; /* Behind the icon */
  }
  
  @keyframes wave-animation {
    0% {
      transform: translate(-50%, 0) scale(1);
    }
    50% {
      transform: translate(-50%, -20px) scale(1.1); /* Move upwards and grow */
    }
    100% {
      transform: translate(-50%, 0) scale(1);
    }
  }
  
  @keyframes floating {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px); /* Slight upward movement */
    }
  }
  