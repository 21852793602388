/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.about-page {
  width: 100%;
  margin: 0 auto;
}

/* Hero Section */
.abouthero-section {
  background-image: url('../image/Slider-2.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 90px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.abouthero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  z-index: 1;
}

.abouthero-content {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
}

.abouthero-content h1 {
  font-size: 3.5em;
  color: white;
}

.abouthero-content p {
  font-size: 1.5em;
  color: white;
}

/* Mission Section */
.mission-section {
  display: flex;
  align-items: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
}

.mission-content {
  flex: 1 1 50%;
  padding: 20px;
}

.mission-image img {
  width: 100%;
  max-width: 400px;
}

/* Team Section */
.team-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f7f7f7;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.team-member {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #25D366; /* A modern green accent color */
  font-family: 'Arial', sans-serif;
}

.team-member p {
  font-size: 1rem;
  color: #666;
  font-family: 'Arial', sans-serif;
  margin-top: 0;
}

@media (max-width: 768px) {
  .team-members {
    flex-direction: column;
    align-items: center;
  }
}


/* Values Section */
.values-section {
  background-color: #e8e8e8;
  text-align: center;
  padding: 60px 20px;
}

.values-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.values-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.value-item {
  max-width: 300px;
  text-align: center;
}

.value-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.value-item p {
  font-size: 1.1em;
}

/* Why Choose Us Section */
.why-choose-us-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.why-choose-us-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.choose-us-list {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.choose-us-list li {
  margin-bottom: 30px;
}

.choose-us-list h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.choose-us-list p {
  font-size: 1.2em;
}

/* Commitment Section */
.commitment-section {
  background-color: #f5f5f5;
  padding: 50px 20px;
  text-align: center;
}

.commitment-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.commitment-section p {
  font-size: 1.2em;
  color: #555;
  max-width: 800px;
  margin: 0 auto 20px;
  line-height: 1.6;
}

/* Responsive Styles */

/* For medium screens (tablets) */
@media (max-width: 768px) {
  .mission-section {
    flex-direction: column-reverse;
  }

  .mission-image img {
    max-width: 100%;
  }

  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    margin-bottom: 30px;
  }
}

/* For small screens (mobiles) */
@media (max-width: 480px) {
  .abouthero-content h1 {
    font-size: 2.0em;
  }

  .abouthero-content p {
    font-size: 1.2em;
    text-align:center;
    
  }

  .team-member img {
    width: 120px;
    height: 120px;
  }

  .mission-content, .value-item {
    padding: 10px;
  }

  .team-members {
    gap: 15px;
  }
}
