/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #e6eff4; /* Very light greyish-blue background */
}

/* Navbar Styles */
.navbar {
  background-color: #33475b; /* Dark Slate Blue for the navbar background */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10; /* Ensure the navbar is above other elements */
}

.navbar-logo {
  color: #ffffff; /* Pure white for logo */
  font-size: 1.5rem;
  font-weight: bold;
}

/* Navigation link styles */
.nav-links {
  display: flex;
  gap: 15px;
  align-items: center;
}

.nav-link {
  color: #f1f1f1; /* Soft white for navbar links */
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px;
  transition: color 0.3s ease;
}

.nav-link.active {
  ccolor: #33475b; /* Active link color - Tomato */
  font-weight: bold; /* Bold for emphasis */
}

.nav-link:hover {
  color: #66d9ff; /* Light cyan blue for hover */
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none; /* Hidden by default */
  font-size: 2rem;
  color: #66d9ff; /* Light cyan blue for the inactive mobile menu icon */
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block; /* Show mobile menu icon on smaller screens */
  }

  .nav-links {
    display: none; /* Initially hidden */
    flex-direction: column;
    background-color: #f4f7fa; /* Light sky blue for mobile menu background */
    position: absolute;
    top: 60px; /* Adjust based on navbar height */
    left: 0;
    width: 100%;
    padding: 20px;
    gap: 20px;
    text-align: center;
    z-index: 9; /* Ensure the mobile menu appears above other elements */
  }

  .nav-links.mobile {
    display: flex; /* Show when mobile menu is active */
  }

  .nav-link {
    font-size: 1.5rem;
    color: #33475b; /* Deep slate blue text for mobile menu links */
  }

  .nav-link:hover {
    color: #66d9ff; /* Light cyan blue for hover in mobile menu */
  }
}
