/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #e6eff4; /* Very light greyish-blue background */
}

.home-page {
  width: 100%;
  margin: 0 auto;
  color: #333;
}

/* Hero Section */
.hero-section {
  background-image: url('../image/Slider-1.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 90px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Set height */
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
}

.hero-content h1 {
  font-size: 3.5em;
  color: #00796b; /* Teal/blue-grey for the heading */
}

.hero-content p {
  font-size: 1.5em;
  color: white; /* Darker teal/blue-grey for the paragraph */
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #f15b2a; /* Bright contrasting button color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}


.cta-buttonc {
  
  padding: 15px 30px;
  background-color: #f15b2a; /* Bright contrasting button color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border-color:black;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.cta-buttonc:hover {
  background-color: #d34b1e; /* Darker shade on hover */
}
/* About Section */
.about-section {
  padding: 80px 20px;
  text-align: center;
  background-color: #d9e3e8; /* Very light grey-blue */
}

.about-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.about-features {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.feature-item {
  max-width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-item img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  object-fit: cover; /* Ensures image maintains aspect ratio */
}

.feature-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Responsive Adjustments for About Section */
@media (max-width: 768px) {
  .about-features {
    flex-direction: column;
  }

  .feature-item {
    max-width: 100%;
  }
}

/* Services Section */
.services-section {
  padding: 80px 20px;
  background-color: #f4f8fb; /* Light grey-blue for contrast */
  text-align: center;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.services-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.service-item {
  max-width: 300px;
}

.service-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.service-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Testimonials Section */
.testimonials-section {
  padding: 80px 20px;
  background-color: #8faab5; /* Same lighter blue-grey as hero section */
  color: white;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.testimonials-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.testimonial-item {
  max-width: 300px;
  background-color: #6e8a99; /* Medium blue-grey */
  padding: 20px;
  border-radius: 10px;
}

.testimonial-item p {
  font-size: 1.2em;
}

.testimonial-item h4 {
  margin-top: 20px;
}

/* Contact Section */
.contact-section1 {
  padding: 80px 20px;
  background-color: #5d7b88; /* Darker blue-grey */
  color: white;
  text-align: center;
}

.contact-section1 h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.contact-section1 p {
  font-size: 1.5em;
  margin-bottom: 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section {
    text-align: center;
  }

  .cta-buttonc {
    margin-left: auto;
    margin-right: auto;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-image {
    margin-top: 30px;
  }

  .about-features, .services-list, .testimonials-list {
    flex-direction: column;
  }

  .feature-item, .service-item, .testimonial-item {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2.0em;
  }

  .hero-content p {
    font-size: 1.2em;
  }

  .cta-buttonc {
    padding: 12px 20px;
    font-size: 1em;
  }

  .about-section h2, .services-section h2, .testimonials-section h2, .contact-section1 h2 {
    font-size: 2em;
  }

  .services-section {
    margin-left: 30px;
  }
}
