/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f7fa; /* Very light grey background for the body */
}

/* Main Section */
.main-section {
  background-image: url('../image/Slider-4.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 90px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh; /* Set height */
}

/* Overlay for text readability */
.main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
}

.main-content {
  position: relative;
  z-index: 2; /* Keeps the text above the overlay */
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
}

.main-content h1 {
  font-size: 3.5em;
  color: white; /* White for the heading */
}

.main-content p {
  font-size: 1.5em;
  color: white; /* White for the paragraph */
}

/* Images Section */
.images-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #d0d8e8; /* Light grey-blue for image section */
}

.image-item {
  text-align: center;
  flex: 1 1 100%; /* Default to full width */
  margin: 10px;
  max-width: 300px;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border: 3px solid #004080; /* Deep blue border for images */
  border-radius: 8px; /* Slightly rounded corners */
}

.image-item p {
  margin-top: 10px;
  font-size: 14px;
  color: #333; /* Dark grey text */
}

/* Additional Section */
.additional-section {
  background-color: #f0f4f8; /* Soft light grey for additional section */
  padding: 30px;
  text-align: center;
}

.additional-section p {
  color: #004080; /* Deep blue text */
  font-size: 18px;
}

/* Services Section */
.services-section {
  padding: 80px 20px;
  background-color: #f4f8fb; /* Light grey-blue for contrast */
  text-align: center;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.services-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.service-item {
  max-width: 300px;
}

.service-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.service-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Partner Industries Section */
.partner-industries-section {
  background-color: #e3f2fd; /* Light blue background for a fresh look */
  color: #333; /* Dark grey text for good contrast */
  padding: 60px 20px;
  text-align: center;
}

.partner-industries-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #00796b; /* Dark teal for the heading */
}

.partner-industries-section p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #004d40; /* Darker teal for the paragraph */
}

.industries-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  gap: 20px; /* Space between items */
}

.industry-item {
  background-color: #ffffff; /* White background for each item */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  max-width: 250px; /* Limit width for consistency */
  text-align: center; /* Center text within each item */
}

.industry-item img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for images */
  margin-bottom: 15px;
}

.industry-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #00796b; /* Dark teal for the heading */
}

.industry-item p {
  font-size: 1em;
  color: #004d40; /* Darker teal for the paragraph */
}

/* Why Choose Us Section */
.why-choose-us-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.why-choose-us-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
}

.choose-us-list {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.choose-us-list li {
  margin-bottom: 30px;
}

.choose-us-list h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.choose-us-list p {
  font-size: 1.2em;
}

/* Media Queries */
@media (min-width: 600px) {
  .image-item {
    flex: 1 1 calc(33.333% - 20px); /* Three columns on larger screens */
  }
}

@media (max-width: 600px) {
  .main-content h1 {
    font-size: 2.0em;
    
  }
  .main-content p {
    font-size: 1.2em;
    
  }
  .main-section,
  .additional-section {
    padding: 20px;
  }

  .image-item {
    flex: 1 1 100%; /* Single column layout on small screens */
  }

  .services-list {
    flex-direction: column; /* Stack services vertically on smaller screens */
  }

  .service-item {
    max-width: 100%; /* Full width for service items on small screens */
  }
}
