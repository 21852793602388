/* General styles */
.get-in-touch-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 30px;
  background: linear-gradient(135deg, #dfe6e9, #b3cde0); /* Lighter blue-grey gradient */
  color: #2c3e50; /* Darker blue-grey text */
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  transition: all 0.3s ease;
}

/* Company Information Section */
.company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 15px;
}

.company-info h2 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50; /* Darker blue-grey */
}

.contact-details {
  margin-top: 20px;
}

.contact-item {
  margin-bottom: 20px;
}

.contact-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #5DADE2; /* Soft blue accent */
}

.contact-item p {
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
}

/* Contact Form Section */
.contact-form-container {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}

.contact-form-container h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #2c3e50; /* Darker blue-grey */
}

/* Form styles */
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #2c3e50;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.6);
  color: #2c3e50;
  transition: background 0.3s ease;
}

.input-field:focus {
  background: rgba(255, 255, 255, 0.8);
}

.error-border {
  border: 2px solid #ff6961;
}

/* Button styles */
.submit-button {
  width: 100%;
  padding: 14px;
  font-size: 18px;
  background-color: #85C1E9; /* Light blue button */
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  background-color: #5DADE2; /* Slightly darker blue hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.error-message {
  color: #ff6961;
  font-size: 14px;
}

/* Contact Section */
.contact-section {
  background-image: url('../image/Slider-5.jpg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 100px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh; /* Set height */
}

/* Overlay for text readability */
.contact-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
}

.contact-content {
  position: relative;
  z-index: 2; /* Keeps the text above the overlay */
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
}

.contact-content h1 {
  font-size: 2.8em;
  color: white;
}

.contact-content p {
  font-size: 1.5em;
  color: white;
}

/* Responsive styling */
@media (max-width: 768px) {
  .get-in-touch-container {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .company-info h2, .contact-form-container h2 {
    font-size: 24px;
  }

  .submit-button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .company-info h2, .contact-form-container h2 {
    font-size: 20px;
  }

  .input-field {
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
  }
}

/* Additional media query for very small screens (320px and below) */
@media (max-width: 320px) {
  .get-in-touch-container {
    padding: 15px;
  }

  .company-info h2, .contact-form-container h2 {
    font-size: 18px;
  }

  .contact-item h3 {
    font-size: 16px;
  }

  .input-field {
    padding: 10px;
    font-size: 12px;
  }

  .submit-button {
    font-size: 12px;
    padding: 10px;
  }
}
