/* Base Styles */
.emr-ehr-page {
  text-align: center;
  padding: 0px
}

.emr-ehr-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
  justify-items: center;
}

.emr-ehr-item {
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.emr-ehr-item img {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.emr-ehr-item:hover img {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.emr-ehr-item h4 {
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
}

.emr-ehr-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .emr-ehr-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .emr-ehr-item img {
    width: 130px;
    height: 130px;
  }

  .emr-ehr-item h4 {
    font-size: 1.1em;
  }
}

@media (max-width: 900px) {
  

  .emr-ehr-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .emr-ehr-item img {
    width: 120px;
    height: 120px;
  }

  .emr-ehr-item h4 {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
 

  .emr-ehr-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .emr-ehr-item img {
    width: 200px;
    height: 200px;
  }

  .emr-ehr-item h4 {
    font-size: 1.2em;
  }
}

/* EMR Section */
.emr-section {
  background-image: url('../image/Slider-4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 90px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.emr-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.emr-content {
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
}

.emr-content h1 {
  font-size: 3.5em;
  color: white;
}

.emr-content p {
  font-size: 1.5em;
  color: white;
}

/* Responsive Adjustments for the EMR Section */
@media (max-width: 768px) {
  .emr-section {
    height: 60vh;
    padding: 50px 20px;
  }

  .emr-content h1 {
    font-size: 2.0em;
  }

  .emr-content p {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
 
}
